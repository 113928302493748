import Base from '../components/Base.js';
import markcell from '../images/sudokupad-demo-markcelleffect.gif';
import caterdokupillar from '../images/caterdokupillar.png';
import styles from './Donate.module.css';

export default function Donate()
{
    /*
    <div className={styles.bitsInteraction}>
        <div className={styles.caterpillarImage}>
            <img src={caterdokupillar} alt="Caterdokupillar" width="100%"></img>
        </div>
        <div>
            To donate to extend the caterdokupillar endurance stream, <a href="/donate/card">click here!</a>
        </div>
    </div>
    */

    return <Base>
        <p>If you missed the fundraiser livestream, you're welcome to <a href="https://www.paypal.com/donate/?hosted_button_id=NXZRDXD3WCBQY" target="_blank" rel="noreferrer">donate directly to our PayPal</a>. Donations of $50 or more will receive a thank-you puzzle - just let us know where to send it! We've surpassed our initial fundraising goal, so 50% of additional donations will now go towards providing scholarships to help more people attend the event.</p>
        <p>You can also subscribe to <a href="https://twitch.tv/sudokucon" target="_blank" rel="noreferrer">our Twitch channel</a>, where you can use Twitch bits to interact with puzzle streamers. Bits are a Twitch Currency that can be purchased at the <a href="https://www.twitch.tv/bits" target="_blank" rel="noreferrer">Purchase Bits Page</a>.
            <div className={styles.bitsInteraction}>
                <div className={styles.interactionImage}>
                    <img src={markcell} alt="Mark cell animation" width="100%"></img>
                </div>
                <div>
                    Getting tired of them ignoring that cell that was resolved 2 years ago? Type <code><div className={styles.chatCode}>Cheer50 !markcell r5c5</div></code> in the chat with the row and column of the cell and at least 50 bits. Once you confirm the bit spending, watch as that cell becomes very hard to miss!
                </div>
            </div>
        </p>
    </Base>
}
