import { useState } from 'react';

import styles from './DonationBar.module.css';
//import background from '../images/sc_2c1p_desertbus.png';

import DonationTracker from '../components/DonationTracker';
import Countdown from '../components/Countdown';

function DonationBar() {
    const [donations, setDonations] = useState([]);

    let lastDonationStr = 'No donations yet';
    if (donations.length) {
        const lastDonation = donations[donations.length - 1];
        lastDonationStr = `Last donation: $${lastDonation.amount.toFixed(2)} by ${lastDonation.name ? lastDonation.name : 'Anonymous'}`
    }

    const raised = donations.reduce((sum, donation) => sum + donation.amount, 0);
    let end = new Date('2024-09-21T05:00:00.000Z');
    let hourCost = 1;
    let remaining = raised;
    console.log('end', end);
    while (remaining >= hourCost) {
        remaining -= hourCost;
        hourCost = Math.floor(hourCost * 140) / 100;
        end = new Date(end.getTime() + 3600000);
        console.log('end', end);
    }
    const progress = 100 * (remaining / hourCost) + '%';

//    <img src={background} style={{ position: 'absolute', width: '100%', height: '100%', zIndex: -2 }}></img>
    return <div className={styles.donationbar}>
        <DonationTracker setDonations={setDonations} />
        <div className={styles.progressbar} style={{ width: progress }} />
        <div className={styles.container}>
            <div style={{ fontSize: '2.9em' }}>Donation total: ${raised.toFixed(2)}</div>
            <div style={{ fontSize: '2.9em', marginLeft: 'auto', marginRight: 'auto', textAlign: 'left' }}>Remaining time: <Countdown endTime={end / 1000} format={'HH:MM'} className={styles.inline} /></div>
            <div className='goal' style={{ fontSize: '1.8em', textAlign: 'left' }}>
                <div>Progress to</div>
                <div>next hour:</div>
            </div>
            <div style={{ fontSize: '2.9em', marginLeft: '30px', textAlign: 'right' }}>${remaining.toFixed(2)}/${hourCost.toFixed(2)}</div>
        </div>
    </div>
}

export default DonationBar;
